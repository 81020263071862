// Fonts
@font-face {
    font-family: 'Ausprobe';
    font-display: swap;
    src: local('Ausprobe'), url(./fonts/Ausprobe/ausprobe.woff2) format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.woff2) format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-weight: 700;
    src: local('Poppins'), url(./fonts/Poppins/Poppins-SemiBold.woff2) format('woff2');
}
$font-family-sans-serif: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$h3-font-size: 1.25rem;
$headings-font-weight: 700;
.ausprobe {
    font-family: 'Ausprobe', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
}

// Navbar
$navbar-light-color:#202020;
$navbar-nav-link-padding-x: 1rem;

$prefix: 'emkfz-';

// Button
$border-radius: 1rem;
$btn-padding-y: .5rem;
$btn-padding-x: 1.5rem;

// Colors
$body-bg: #ECECEC;
$body-color: #202020;

$primary:#202020;
$secondary: #C2C2C2;
$dark: #202020;
$light: #ECECEC;

$headings-margin-bottom: 1.5rem;

// $input-border-color: rgba($primary, .75);
$input-border-color: #C2C2C2;

// Custom classes
@media(min-width: 992px) { // >=large
    .sticky-nav {
        top: 100px!important;
    }
    h2, h3 { // On-Page TOC
        scroll-margin-top: 110px!important;
    }
}
h2, h3 { // On-Page TOC
    scroll-margin-top: 520px;
}

.navbar-toggler.collapsed > svg {
    rotate: 180deg;
}

.hero {
    max-height: 50vh;
    object-fit: cover;
}

@import "~bootstrap/scss/bootstrap.scss"